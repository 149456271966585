import axios from "axios";

// const API_URL = "http://localhost:3000/api/test/";
const API_URL = "/api/test/";
// const API_URL_FORMS = "http://localhost:3000/api/forms/";
const API_URL_FORMS = "/api/forms/";
const API_URL_PRICE = "/api/price/";
const API_URL_PROFIT = "/api/profitability/";
// api/profitability/labour-cost
const getPublicContent = async () => {
  return await axios.get(API_URL_FORMS + "all");
};

const getAllJobNames = async () => {
  return await axios.get(API_URL_FORMS + "job-names");
};

const getJobDetail = async (project_id) => {
  return await axios.get(API_URL_FORMS + "job/" + project_id);
};

const listShingleUpgrades = async (project_id) => {
  return await axios.get(API_URL_FORMS + "list-shingle-upgrades/" + project_id);
};

const downloadFile = async (project_id) => {
  try{
    const response = await axios.get(API_URL_FORMS + "download/" + project_id, {
      responseType: "blob", // Important: This tells axios to handle the response as a Blob
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.download = "report.pdf"; // Replace with the desired file name
    document.body.appendChild(link);
    link.click();
  
    // Clean up
    link.remove();
    window.URL.revokeObjectURL(url);
  }
  catch(error){
    console.error('Error during file download:', error);
  }
  
};

const listGutterUpgrades = async (project_id) => {
  return await axios.get(API_URL_FORMS + "list-gutter-upgrades/" + project_id);
};

const shingleUpgrade = async (project_id) => {
  return await axios.get(API_URL_FORMS + "shingle-upgrade/" + project_id);
};

const saveShingleUpgrades = async (body) => {
  return await axios.post(API_URL_FORMS + "save-shingle-upgrades", body);
};

const saveGutterUpgrades = async (body) => {
  return await axios.post(API_URL_FORMS + "save-gutter-upgrades", body);
};

const removeShingleUpgrades = async (body) => {
  return await axios.post(API_URL_FORMS + "remove-shingle-upgrades", body);
};

const removeGutterUpgrades = async (body) => {
  return await axios.post(API_URL_FORMS + "remove-gutter-upgrades", body);
};

const deleteJob = async (project_id) => {
  return await axios.post(API_URL_FORMS + "delete-job", { id: project_id });
};

const saveCommision = async (body) => {
  return await axios.post(API_URL_FORMS + "save-commmision", body);
};

const getLabourProfit = async (project_id) => {
  return await axios.get(API_URL_PROFIT + "labour-cost/" + project_id);
};

const getMaterialProfit = async (project_id) => {
  return await axios.get(API_URL_PROFIT + "material-cost/" + project_id);
};

const getJobProfit = async (project_id) => {
  return await axios.get(API_URL_PROFIT + "job-cost/" + project_id);
};

const getCostDistribution = async (project_id) => {
  return await axios.get(API_URL_PROFIT + "cost-distribution/" + project_id);
};

const updateMaterialProfit = async (project_id) => {
  return await axios.post(API_URL_PROFIT + "update-material-cost", {
    project_id,
  });
};

const updateLaborProfit = async (project_id) => {
  return await axios.post(API_URL_PROFIT + "update-labor-cost", { project_id });
};

const createProjectForm = (formData) => {
  return axios.post(API_URL_FORMS + "create", formData);
};

const getProjectForm = (project_id) => {
  return axios.get(API_URL_FORMS + "get-project/" + project_id);
};

const getAccessoryPrice = () => {
  return axios.get(API_URL_PRICE + "accesory-price");
};

const getLaborPrice = () => {
  return axios.get(API_URL_PRICE + "labor-price");
};

const getShinglePrice = () => {
  return axios.get(API_URL_PRICE + "shingle-price");
};

const saveShinglePrices = (shingleData) => {
  return axios.post(API_URL_PRICE + "save-shingle-price", {
    shingleData: shingleData,
  });
};

const saveLaborPrices = (laborData) => {
  return axios.post(API_URL_PRICE + "save-labor-price", { laborData });
};

const saveAccessoryPrices = (accessoryData) => {
  return axios.post(API_URL_PRICE + "save-accessory-price", { accessoryData });
};

const getUserBoard = () => {
  return axios.get(API_URL + "user");
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod");
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin");
};

const UserService = {
  getPublicContent,
  createProjectForm,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getAllJobNames,
  getJobDetail,
  getAccessoryPrice,
  getLaborPrice,
  getShinglePrice,
  saveShinglePrices,
  saveLaborPrices,
  saveAccessoryPrices,
  getLabourProfit,
  getMaterialProfit,
  getJobProfit,
  saveCommision,
  updateMaterialProfit,
  updateLaborProfit,
  getCostDistribution,
  deleteJob,
  shingleUpgrade,
  saveShingleUpgrades,
  saveGutterUpgrades,
  removeShingleUpgrades,
  removeGutterUpgrades,
  listShingleUpgrades,
  listGutterUpgrades,
  getProjectForm,
  downloadFile,
};

export default UserService;
