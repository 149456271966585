// src/MaterialOrder.js
import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { Tabs, Tab, Box, CircularProgress, Button } from "@mui/material";

const MaterialOrderForm = (props) => {
  // const [projectId, setProjectId] = useState("");
  const projectId = props?.projectId;
  const setProjectId = props?.setProjectId;
  const setSlelectedJob = props?.setSlelectedJob;
  const loading = props?.loading;
  const [jobNames, setJobNames] = useState([]);
  const [selectedJobName, setSelectedJobName] = useState("");
  const [material, setMaterial] = useState({
    shingleBrand: "",
    shingleType: "",
    bundles: 0,
    underlayment: 0,
    starterStrip: 0,
    hipRidge: 0,
    iceWater: 0,
    dripEdge: 0,
    turbineVents: 0,
    turtleBoxVents: "",
    pipeBoot: 0,
    ridgeVent: 0,
    coilNails: 0,
    plasticCapNails: 0,
    caulk: 0,
    sprayPaint: 0,
    jobAddress: "",
    jobNotes: "",
    shingleColor: "",
    skylight: "No",
    chimney: 0,
    estimatedJobDate: "",
    jobTerritory: "",
  });

  useEffect(() => {
    const fetchJobNames = async () => {
      try {
        const data = await UserService.getAllJobNames();
        if (data.data?.success) {
          setJobNames(data.data.job_names);
        }
      } catch (error) {
        console.error("Error fetching job names:", error);
      }
    };

    fetchJobNames();
  }, []);

  useEffect(() => {
    const fetchJobDetail = async () => {
      if (projectId) {
        try {
          const data = await UserService.getJobDetail(projectId);
          if (data.data?.success) {
            const jobDetails = data.data.job || {};
  
            // Safeguard calculations with defaults
            const total_number_of_squares = jobDetails.total_number_of_squares || 0;
            const total_parameter_length = jobDetails.total_parameter_length || 0;
            const total_ridge_length = jobDetails.total_ridge_length || 0;
            const total_hip_length = jobDetails.total_hip_length || 0;
            const total_valley_length = jobDetails.total_valley_length || 0;
            const bundles =total_number_of_squares * 3 + total_number_of_squares * 0.1;            const coilNails = Math.ceil(total_number_of_squares / 18) || 0;
            const plasticCapNails = Math.ceil(total_number_of_squares / 35) || 0;
            const caulk = Math.ceil(jobDetails.number_of_pipe_boots / 2) || 0;
            const underlayment = Math.ceil(total_number_of_squares / 10);
            const starterStrip = Math.ceil(total_parameter_length / 106);
            const dripEdge = Math.ceil(total_parameter_length / 10);
            const hipRidge = Math.ceil((total_ridge_length + total_hip_length) / 30);
            const iceWater = Math.ceil(total_valley_length / 60);
            const turbine = jobDetails.VentilationCount || 0;
            const sprayPaint = Math.ceil(turbine + (jobDetails.number_of_pipe_boots || 0) / 4) || 0;
  
            // Log details for debugging
            console.log("Job Details: ", jobDetails);
  
            setMaterial({
              shingleBrand: jobDetails.shingle_manufacture || "",
              shingleType: jobDetails.shingle_type || "",
              bundles: bundles.toFixed(2),
              underlayment: underlayment,
              starterStrip: starterStrip,
              hipRidge: hipRidge,
              iceWater: iceWater,
              dripEdge: dripEdge,
              turbineVents: turbine,
              turtleBoxVents:
                jobDetails.typeOfVentilation === "turtle_box"
                  ? jobDetails.VentilationCount
                  : "none",
              pipeBoot: jobDetails.number_of_pipe_boots || 0,
              ridgeVent:
                jobDetails.typeOfVentilation === "ridge"
                  ? jobDetails.VentilationCount
                  : 0,
              coilNails: coilNails,
              plasticCapNails: plasticCapNails,
              caulk: caulk,
              sprayPaint: sprayPaint,
              jobAddress: `${jobDetails.street_address || ""}, ${jobDetails.city || ""}, ${jobDetails.state || ""}, ${jobDetails.zip || ""}`,
              jobNotes: jobDetails.additinal_information || "",
              shingleColor: jobDetails.shingle_color || "",
              skylight: jobDetails.skylight || "No",
              chimney: jobDetails.chimney || 0,
              estimatedJobDate: jobDetails.contract_signed_dated || "",
              jobTerritory: jobDetails.territory || "",
            });
          } else {
            console.error("Failed to fetch job details");
          }
        } catch (error) {
          console.error("Error fetching job details:", error);
        }
      }
    };
  
    fetchJobDetail();
  }, [projectId]);
  

  const handleJobChange = (event) => {
    setProjectId(event.target.value);
    const job = jobNames.find((job) => job.id === event.target.value);
    setSlelectedJob(job?.name);
  };
  return (
    <div className="p-6 min-h-screen   text-gray-100">
      <style>
        {`
      .welcome-text {
        font-family: 'Pacifico', cursive;
      }
    `}
      </style>
      <div
        className="container mx-auto p-6 rounded-lg shadow-lg"
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <h1
            className="text-3xl welcome-text text-black"
            style={{ fontFamily: "Times New Roman, serif" }}
          >
            Best Choice Roofing of Arkansas
          </h1>
         
        </div>
        <h2
          className="text-l font-bold text-center text-black"
          style={{ marginTop: "45px", marginBottom: "45px" }}
        >
          Material Order Form
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <table className="table-auto w-full text-left">
              <tbody>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2 text-black">Shingle Brand:</td>
                  <td className="px-4 py-2 text-black">
                    {material.shingleBrand}
                  </td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2 text-black">Shingle Type:</td>
                  <td className="px-4 py-2 text-black">
                    {material.shingleType}
                  </td>
                </tr>
                
                <tr className="border-b border-black-700">
                <td className="px-4 py-2 text-black">
                  # of Bundles (10% waste factor):
                </td>
                <td className="px-4 py-2 text-black">
                  {Math.round(material.bundles)}
                </td>
                </tr>

                {/* 
                <tr className="border-b border-gray-700">
                  <td className="px-4 py-2"># of Bundles (10% waste factor):</td>
                  <td className="px-4 py-2">{Math.round(material.bundles)}</td>

                </tr> */}
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2 text-black">
                    Rolls of Underlayment (10SQ):
                  </td>
                  <td className="px-4 py-2 text-black">
                    {material.underlayment}
                  </td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2 text-black">
                    Bundles of Starter Strip:
                  </td>
                  <td className="px-4 py-2 text-black">
                    {material.starterStrip}
                  </td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2 text-black">
                    Bundles of Hip/Ridge:
                  </td>
                  <td className="px-4 py-2 text-black" text-black>
                    {material.hipRidge}
                  </td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2 text-black">
                    Rolls of Ice & Water:
                  </td>
                  <td className="px-4 py-2 text-black">{material.iceWater}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Sticks of Drip Edge (10'):</td>
                  <td className="px-4 py-2">{material.dripEdge}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Turbine Vents:</td>
                  <td className="px-4 py-2">{material.turbineVents}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Turtle/Box Vents:</td>
                  <td className="px-4 py-2">{material.turtleBoxVents}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Pipe Boot:</td>
                  <td className="px-4 py-2">{material.pipeBoot}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Ridge Vent Stick (4'):</td>
                  <td className="px-4 py-2">{material.ridgeVent}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Box of Coil Nails (1-1/4"):</td>
                  <td className="px-4 py-2">{material.coilNails}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Pail of Plastic Cap Nails:</td>
                  <td className="px-4 py-2">{material.plasticCapNails}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Tubes of Caulk:</td>
                  <td className="px-4 py-2">{material.caulk}</td>
                </tr>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Cans of Spray Paint:</td>
                  <td className="px-4 py-2">{material.sprayPaint}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table className="table-auto w-full text-left text-black">
              <tbody>
                <tr className="border-b border-black-700 text-black">
                  <td className="px-4 py-2">Job Name:</td>
                  <td className="px-4 py-2">
                    <select
                      onChange={handleJobChange}
                      value={projectId}
                      className="w-full border p-2 rounded text-black"
                      style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                    >
                      <option value="">Select Job</option>
                      {jobNames.map((jobName) => (
                        <option key={jobName.id} value={jobName.id}>
                          {jobName.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Job Address:</td>
                  <td className="px-4 py-2">{material.jobAddress}</td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Job Notes:</td>
                  <td className="px-4 py-2">{material.jobNotes}</td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Shingle Color:</td>
                  <td className="px-4 py-2">{material.shingleColor}</td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Skylight:</td>
                  <td className="px-4 py-2">{material.skylight}</td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Chimney:</td>
                  <td className="px-4 py-2">{material.chimney}</td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Estimated Job Date:</td>
                  <td className="px-4 py-2">{material.estimatedJobDate}</td>
                </tr>
                <tr className="border-b border-black-700">
                  <td className="px-4 py-2">Job Territory:</td>
                  <td className="px-4 py-2">{material.jobTerritory}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialOrderForm;
