
import React, { useState, useRef } from "react";
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";
import UserService from "../../services/user.service";
import MaterialOrderForm from "./MaterialOrder";
import MaterialCost from "./MaterialCost";
import LaborCost from "./LaborCost";
import JobCost from "./JobCost";
import Packet from "./Packet";
import Upgrades from "./Upgrades";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";


const TabPanel = (props) => {
  const { children, value, index, panelRef, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      ref={panelRef}
      {...other}
      style={{
        height: "calc(100vh - 100px)", // Adjust height as needed
        overflowY: "auto", // Enable scrolling inside the tab panel
        padding: "16px",
      }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const Profitability = () => {
  const [value, setValue] = useState(0);
  const [projectId, setProjectId] = useState("");
  const [slelectedJob, setSlelectedJob] = useState("");
  const [loading, setLoading] = useState(false);
  const panelRefs = [useRef(), useRef(), useRef(), useRef(), useRef(),useRef()];
  const [loadingStates, setLoadingStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [jobCostData, setJobCostData] = useState(null);
  const [selectedJobName, setSelectedJobName] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleJobNameChange = (name) => {
    setSelectedJobName(name);
  };

  const handleDownloadFile = async () =>{
    try{
      await UserService.downloadFile(projectId)
    }
    catch(error){

    }
  }

  const handleDownload = async () => {
    setLoading(true);
  
    const doc = new jsPDF("p", "pt", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    let startY = margin;
  
    // Prepare Job Cost Table Data
    const jobCostFields = jobCostData ? [
      { label: "Total Contract Amount", value: `$ ${jobCostData.total_contract_amount || 0}` },
      { label: "Minus Labor", value: `$ ${jobCostData.minus_labor_cost || 0}` },
      { label: "Minus Material", value: `$ ${jobCostData.minus_material_cost || 0}` },
      { label: "Reps Commission", value: `$ ${jobCostData.minus_reps_commission || 0} (${jobCostData.reps_commision || 0}%)` },
      { label: "Franchise Fee", value: `$ ${jobCostData.minus_franchise_fee || 0} (${jobCostData.franchise_fee || 0}%)` },
      { label: "SM Override", value: `$ ${jobCostData.minus_sm_override || 0} (${jobCostData.sm_override || 0}%)` },
      { label: "COGS", value: `$ ${jobCostData.cogs || 0}` },
      { label: "Gross Profit", value: `$ ${jobCostData.gross_profit || 0}` },
      { label: "Office Fee", value: `$ ${jobCostData.minus_office_fee || 0} (${jobCostData.office_fee || 0}%)` },
      { label: "Net Profit", value: `$ ${jobCostData.net_profit || 0}` },
      { label: "Gross Margin", value: `$ ${jobCostData.gross_profit || 0} (${jobCostData.gross_margin || 0}%)` },
      { label: "Net Margin", value: `$ ${jobCostData.net_profit || 0} (${jobCostData.net_margin || 0}%)` },
      { label: "Cost Per Sq (Mat+Labor)", value: `$ ${jobCostData.cost_per_sq || 0}` },
      { label: "Total Revenue Per Sq", value: `$ ${jobCostData.total_rev_per_sq || 0}` },
      { label: "Reps Commission", value: `$ ${jobCostData.minus_reps_commission || 0} (${jobCostData.reps_commision || 0}%)` },
      { label: "Company", value: `$ ${jobCostData.net_profit || 0}` },
      { label: "Corp", value: `$ ${jobCostData.minus_franchise_fee || 0} (${jobCostData.franchise_fee || 0}%)` },
      { label: "Install", value: `$ ${jobCostData.minus_labor_cost || 0} (${jobCostData.labor_percent || 0}%)` },
      { label: "Supply House", value: `$ ${jobCostData.minus_material_cost || 0} (${jobCostData.percent_material_cost || 0}%)` },

    ] : [];
  
    // Title for the entire section
    const title = "Job Cost";
    const titleWidth = doc.getTextWidth(title);
    const centerX = (pageWidth - titleWidth) / 2;
    
    // Add title
    doc.setFontSize(14);
    doc.text(title, centerX, startY);
    startY += 20;
  
    // Prepare to get Job Cost data from the tab
    setValue(0);
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    // Wait for the loading state to complete
    while (loadingStates[0]) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    // Extract data from the panel
    const element = panelRefs[0].current;
    if (element) {
      const headers = element.querySelectorAll("h1, h2");
      headers.forEach((header) => header.remove());
  
      const rowsToRemove = element.querySelectorAll(".exclude-from-pdf");
      rowsToRemove.forEach((row) => row.remove());
  
      const textContent = element.innerText || element.textContent;
      let lines = textContent.split("\n");
      let rows = lines.map((line) => line.split("\t"));
  
      // Process job name if needed
      let job_name_index = -1;
      let job_index = -1;
      let count = 0;  
      rows = rows.map((row, index) => {
        if (row[0] === "Job Name:") {
          job_index = index;
          job_name_index = index;
          row[1] = slelectedJob || row[1];
        }
        if (row[0] === "Job Address:") {
          job_name_index = -1;
        }
        if (job_name_index >= 0 && row[0] !== "Job Name:") {
          count++;
        }
        return row;
      });     
      if (job_index !== -1) {
        rows.splice(job_index + 1, count);
      }
  
      // Filter out unnecessary rows
      rows = rows.filter((row) => row[0] !== "Update Price");
      rows = rows.filter((row) => row[0] !== "DOWNLOAD");
  
      // Combine job cost details and extracted job cost data
      const combinedBody = [
        ...rows,
        ...jobCostFields.map(field => [field.label, field.value])
      ];
  
      // Draw table using autoTable
      autoTable(doc, {
        startY: startY,
        head: [['Job', 'Cost']],
        body: combinedBody,
        headStyles: { fillColor: [255, 0, 0], halign: 'center' },
        theme: "grid",
        styles: { font: "helvetica", fontSize: 10, cellPadding: 5, halign: 'center' },
      });
  
      // Update startY for the next sections
      startY = doc.lastAutoTable.finalY + 20;
    }
  
    // Continue with other sections as before
    const tabs_index = [3, 4, 5];
    for (const i of tabs_index) {
      setValue(i);
      await new Promise((resolve) => setTimeout(resolve, 1000));
  
      while (loadingStates[i]) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
  
      const element = panelRefs[i].current;
      if (!element) continue;
  
      const headers = element.querySelectorAll("h1, h2");
      headers.forEach((header) => header.remove());
  
      const rowsToRemove = element.querySelectorAll(".exclude-from-pdf");
      rowsToRemove.forEach((row) => row.remove());
  
      const textContent = element.innerText || element.textContent;
      let lines = textContent.split("\n");
      let rows = lines.map((line) => line.split("\t"));
  
      // Process job name if needed
      let job_name_index = -1;
      let job_index = -1;
      let count = 0;  
      rows = rows.map((row, index) => {
        if (row[0] === "Job Name:") {
          job_index = index;
          job_name_index = index;
          row[1] = slelectedJob || row[1];
        }
        if (row[0] === "Job Address:") {
          job_name_index = -1;
        }
        if (job_name_index >= 0 && row[0] !== "Job Name:") {
          count++;
        }
        return row;
      });     
      if (job_index !== -1) {
        rows.splice(job_index + 1, count);
      }
  
      rows = rows.filter((row) => row[0] !== "Update Price");
      rows = rows.filter((row) => row[0] !== "DOWNLOAD");
  
      const getHeadersForSection = (sectionIndex) => {
        switch (sectionIndex) {
          case 3:
            return [["Materials", "Values"]];
          case 4:
            return [["Brand", "Type", "Unit Price", "Unit", "Total"]];
          case 5:
            return [["Items", "Unit Price", "Unit", "Total"]];
          default:
            return [];
        }
      };
  
      const getTitleForSection = (sectionIndex) => {
        switch (sectionIndex) {
          case 3:
            return "Material Order Form";
          case 4:
            return "Material Cost";
          case 5:
            return "Labor Cost";
          default:
            return "";
        }
      };
  
      const sectionHeaders = getHeadersForSection(i);
      const title = getTitleForSection(i);
      const titleWidth = doc.getTextWidth(title);
      const centerX = (pageWidth - titleWidth) / 2;
  
      // Add title for each section
      doc.setFontSize(12);
      doc.text(title, centerX, startY);
      startY += 20;
  
      // Draw table using autoTable
      autoTable(doc, {
        startY: startY,
        head: sectionHeaders,
        body: rows,
        headStyles: { fillColor: [255, 0, 0], halign: 'center' },
        theme: "grid",
        styles: { font: "helvetica", fontSize: 10, cellPadding: 5, halign: 'center' },
      });
  
      // Update startY for the next table
      startY = doc.lastAutoTable.finalY + 20;
  
      // Check if we need to add a new page
      if (startY > doc.internal.pageSize.height - margin) {
        doc.addPage();
        startY = margin;
      }
    }
  
    doc.save("profitability_report.pdf");
    setLoading(false);
  };

  const handleLoadingStateChange = (index, isLoading) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = isLoading;
      return newStates;
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile

          style={{
            position: "fixed",
            top: 80,
            zIndex: 1000,
            backgroundColor: "#ffffff", // Set background color to white
            width: "100%", // Ensure tabs take the full width
            borderBottom: "1px solid #e0e0e0", // Add a bottom border for separation
          }}
        >
           <Tab label="Job Cost" {...a11yProps(0)} />
           <Tab label="Upgrades" {...a11yProps(1)} />
           <Tab label="Packet" {...a11yProps(2)} />
          <Tab label="Material Order Form" {...a11yProps(3)} />
          <Tab label="Material Cost" {...a11yProps(4)} />
          <Tab label="Labor Cost" {...a11yProps(5)} />
         
         
        </Tabs>

      </div>
      <div style={{ marginTop: "48px" /* Adjust as needed to account for fixed tabs */ }}>
        {panelRefs.map((panelRef, index) => (
          <TabPanel key={index} value={value} index={index} panelRef={panelRef}>

            {index === 0 && (
              <JobCost
              setProjectId={setProjectId}
              // onJobNameChange={handleJobNameChange}
              onJobNameChange={(name) => setSlelectedJob(name)}
              setSlelectedJob={setSlelectedJob}
              handleDownload={handleDownloadFile}
                projectId={projectId}
                loading={loading}
                onJobCostDataChange={(data) => setJobCostData(data)}

              />
            )}
          
              {index === 1 && 
              <Upgrades
             projectId={projectId}
            />} 
              {index === 2 && 
            <Packet
             projectId={projectId}
            />}
                        {index === 3 && (
              <MaterialOrderForm
                projectId={projectId}
                setProjectId={setProjectId}
                onJobNameChange={handleJobNameChange}
                setSlelectedJob={setSlelectedJob}
                handleDownload={handleDownload}
                loading={loading}
              />
            )}
            {index === 4 && (
              <MaterialCost
                projectId={projectId}
                setLoadingState={(isLoading) =>
                  handleLoadingStateChange(index, isLoading)
                }
              />
            )}
            {index === 5 && (
              <LaborCost
                projectId={projectId}
                setLoadingState={(isLoading) =>
                  handleLoadingStateChange(index, isLoading)
                }
              />
            )}
              

          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default Profitability;