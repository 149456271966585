import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import UserService from "../services/user.service";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ContractList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [contracts, setContracts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  const recordsPerPage = 10;
  const totalPages = Math.ceil(contracts.length / recordsPerPage);

  useEffect(() => {
    const fetchJobNames = async () => {
      setLoading(true);
      try {
        const data = await UserService.getAllJobNames();
        if (data.data?.success) {
          setContracts(data.data.job_names);
        }
      } catch (error) {
        console.error("Error fetching job names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobNames();
  }, []);

  const handleDeleteConfirmation = (id) => {
    setSelectedContractId(id);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    if (selectedContractId) {
      setLoading(true);
      try {
        await UserService.deleteJob(selectedContractId);
        setContracts(
          contracts.filter((contract) => contract.id !== selectedContractId)
        );
        setOpenDialog(false);
        setSelectedContractId(null);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setAlertMsg(error.response.data.message);
        } else {
          setAlertMsg(error.message);
        }
        setOpen(true);
        setOpenDialog(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (contract) => {
    // Navigate to the /form route with the contract ID as a query parameter
    navigate(`/form?id=${contract.id}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpenDialog(false);
    setSelectedContractId(null);
  };

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  const paginatedContracts = contracts.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  return (
    <div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "450px", overflowY: "auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Contract Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedContracts.map((contract) => (
                  <TableRow key={contract.id}>
                    <TableCell>{contract.name}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(contract)}
                        disabled={loading}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteConfirmation(contract.id)}
                        disabled={loading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </>
      )}

      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contract? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContractList;
